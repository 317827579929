import { useParams } from "react-router-dom"

import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import PatientDocumentsListV2 from "app/main/patient-orders/patient-documents/PatientDocumentsListV2"
import Card from "app/patient-portal/Card"
import { PatientPortalUser } from "app/patient-portal/types"
import useCachedResource from "app/swr/hooks/use-cached-resource"

const PatientPortalUserDocuments = () => {
  const [patientPortalDocumentsEnabled] = useFeatureFlag(
    FeatureFlag.PatientPortalDocuments
  )

  const { userId } = useParams<{
    userId: string
  }>()
  const user = useCachedResource<PatientPortalUser>({
    id: userId,
    type: "patient_portal_user",
  })

  if (!patientPortalDocumentsEnabled) {
    return (
      <Card className="h-full flex">
        <div className="justify-center items-center text-navy font-semibold">
          Whoops... this page is not currently available.
        </div>
      </Card>
    )
  }

  return (
    <Card className="h-full">
      <div className="text-navy text-lg font-semibold">Documents</div>
      <div className="h-[1px] w-full bg-slate-200"></div>

      <PatientDocumentsListV2 inPatientPortal={true} user={user} />
    </Card>
  )
}

export default PatientPortalUserDocuments
