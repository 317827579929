import React, { useCallback } from "react"

import clsx from "clsx"

import { ReactComponent as ResultsEditIcon } from "app/assets/icons/flask.svg"
import { ReactComponent as OrderedResultDocumentIcon } from "app/assets/images/ordered-result-document-icon.svg"
import useEventCallback from "app/hooks/use-event-callback"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useAppSelector from "app/hooks/useAppSelector"
import useBloodReportActionsEnabledForOrderedResult from "app/main/blood-lab-dashboards/hooks/use-blood-report-actions-enabled-for-ordered-result"
import useResultsUploadPdfModal from "app/main/results-upload/modals/ResultsUploadPdfModal"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Document } from "types/patient-with-documents-v2"
import { UserResult } from "types/user-result"

import DocumentCardActionsMenu from "./DocumentCardActionsMenu"
import PatientDocumentCard from "./PatientDocumentCard"
import { useBloodReportModalV2 } from "./hooks/use-blood-report-modal"

interface Props {
  patientId: string
  document: Document
}

const OrderUserResultDocumentCard: React.FC<Props> = ({
  patientId,
  document,
}) => {
  const userResult = useCachedResource<UserResult>(
    document.relationships?.user_result?.data
  )

  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const [isManualTrendsEnabled] = useFeatureFlag(
    FeatureFlag.ResultsOverTimeManualResultsUpload
  )

  const { isBloodReportActionEnabled } =
    useBloodReportActionsEnabledForOrderedResult({
      supportsBloodReport: userResult?.attributes?.supports_blood_report,
      hasSnapshots:
        !!userResult?.relationships?.blood_lab_dashboard_report_snapshots?.data
          ?.length,
      clinicFeatures: practitioner.clinic?.clinic_features,
    })

  const supportsTrends =
    userResult?.attributes.supports_trends_visualization &&
    isManualTrendsEnabled

  // If we have snapshots and we support trends, item should be in trends
  const visibleInTrends =
    supportsTrends &&
    (userResult?.relationships?.blood_lab_dashboard_report_snapshots?.data
      ?.length ?? 0) > 0

  const canDownload = document.meta.can_read && userResult?.attributes.file
  const canEdit = document.meta.can_write && !visibleInTrends

  // TODO: pass date_results_sent_to_patient
  const { onModalOpen } = useBloodReportModalV2(userResult, undefined)

  const resultsUploadPdfModal = useResultsUploadPdfModal()

  const onClick = useEventCallback(() => {
    if (canEdit && isBloodReportActionEnabled) {
      onModalOpen()
    } else if (canDownload) {
      onDownload()
    }
  })

  const onDownload = () => {
    if (canDownload) {
      window.open(userResult!.attributes.file)
    }
  }

  const onConvertToTrends = () => {
    if (canEdit) {
      resultsUploadPdfModal.show({
        onClose: () => resultsUploadPdfModal.remove(),
        existingUserResultId: userResult?.id,
        patientId,
      })
    }
  }

  const Icon = useCallback(
    (className: string) => {
      if (userResult?.attributes.result_file_thumbnail) {
        return (
          <img
            className={clsx(className, "w-[90px] h-[111px]")}
            src={userResult.attributes.result_file_thumbnail}
            alt="Report Thumbnail"
          />
        )
      }
      return <OrderedResultDocumentIcon className={className} />
    },
    [document]
  )

  return (
    <PatientDocumentCard
      onClick={onClick}
      Icon={Icon}
      preTitle={userResult?.attributes.lab_company_name?.toUpperCase()}
      title={
        isBloodReportActionEnabled
          ? "Blood Panel Report"
          : userResult?.attributes.display_name
      }
      subTitle={document.attributes.origin_description}
      actions={
        isManualTrendsEnabled ? (
          <DocumentCardActionsMenu
            onDownload={canDownload ? onDownload : undefined}
            onDelete={undefined}
            deleteLabel={undefined}
            onEdit={canEdit ? onConvertToTrends : undefined}
            editIcon={canEdit ? <ResultsEditIcon /> : undefined}
            editLabel={canEdit ? "Prepare to Track in Trends" : undefined}
          />
        ) : null
      }
    />
  )
}

export default OrderUserResultDocumentCard
