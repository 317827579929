import { Checkbox } from "@material-ui/core"
import { cn } from "@rupahealth/design"

import { ReactComponent as CheckIcon } from "app/assets/icons/check-circle.svg"
import { ReactComponent as CircleIcon } from "app/assets/icons/empty-circle.svg"
import { primaryColor, white } from "app/theme"
import { UserClinicAccessSharingStatus } from "app/types"

import ClinicDocumentAccessDocuments from "./ClinicDocumentAccessDocuments"
import ClinicDocumentAccessFutureSharing from "./ClinicDocumentAccessFutureSharing"
import { useClinicDocumentAccessModalContext } from "./ClinicDocumentAccessModalProvider"

const ClinicDocumentAccessOption = ({
  optionSharingStatus,
  text,
}: {
  optionSharingStatus: UserClinicAccessSharingStatus
  text: string
}) => {
  const { sharingStatus, setSharingStatus } =
    useClinicDocumentAccessModalContext()

  const onClick = () => {
    setSharingStatus(optionSharingStatus)
  }

  const selected = sharingStatus === optionSharingStatus
  return (
    <div
      className={cn("my-2 p-1 rounded-xl border ", {
        "border-primary bg-blue-50": selected,
        "border-slate-300 hover:border-primary bg-white": !selected,
      })}
    >
      <div className={"flex flex-row"} onClick={onClick}>
        <Checkbox
          icon={
            <CircleIcon
              height={20}
              width={20}
              fill={white}
              className="border-slate-300 border rounded-full"
            />
          }
          checkedIcon={
            <CheckIcon
              fill={primaryColor}
              viewBox="0 0 14 14"
              width={20}
              height={20}
            />
          }
          checked={selected}
        />
        <p
          className={cn("self-center font-semibold", {
            "text-primary": selected,
          })}
        >
          {text}
        </p>
      </div>
      {optionSharingStatus === UserClinicAccessSharingStatus.SPECIFIC &&
        selected && (
          <div className="bg-white m-2 p-4">
            <ClinicDocumentAccessDocuments />
            <ClinicDocumentAccessFutureSharing />
          </div>
        )}
    </div>
  )
}

export default ClinicDocumentAccessOption
