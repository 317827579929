import React from "react"

import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { Dialog, DialogContent } from "@rupahealth/design"

import ClinicDocumentAccessModalInner from "./components/ClinicDocumentAccessModalInner"
import ClinicDocumentAccessModalProvider, {
  ClinicDocumentAccessModalProviderProps,
  useClinicDocumentAccessModalContext,
} from "./components/ClinicDocumentAccessModalProvider"

export interface CredentialsModalProps
  extends Omit<ClinicDocumentAccessModalProviderProps, "children"> {}

const ClinicDocumentAccessModal: React.FC = () => {
  const { modalOpen, closeModal } = useClinicDocumentAccessModalContext()

  const onOpenChange = (open: boolean) => {
    if (!open) closeModal()
  }

  return (
    <Dialog open={modalOpen} onOpenChange={onOpenChange}>
      <DialogContent className="bg-slate-100 p-0 max-h-[90vh] overflow-y-auto max-w-md border-[4px] rounded-xl">
        <ClinicDocumentAccessModalInner />
      </DialogContent>
    </Dialog>
  )
}

const ClinicDocumentAccessNiceModal = NiceModal.create<CredentialsModalProps>(
  (props) => (
    <ClinicDocumentAccessModalProvider {...props}>
      <ClinicDocumentAccessModal {...props} />
    </ClinicDocumentAccessModalProvider>
  )
)

export function useClinicDocumentAccessModal() {
  return useModal(ClinicDocumentAccessNiceModal)
}
