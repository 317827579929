import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import clsx from "clsx"
import { useHistory, useLocation, useParams } from "react-router-dom"

import { Container, useMediaQuery } from "@material-ui/core"

import { UserPaths } from "app/Routes"
import AddIcon from "app/assets/icons/add-white.svg"
import HouseGrayIcon from "app/assets/icons/house-gray.svg"
import Loading from "app/components/Loading"
import PageToolbar from "app/components/PageToolbar"
import RupaButton from "app/components/design-system/Button"
import { ORDER_STATUS } from "app/constants"
import FoodPlanCreateForPatientButton from "app/food-plans/components/FoodPlanCreateForPatientButton"
import PatientFoodPlanListToolbar from "app/food-plans/components/PatientFoodPlanListToolbar"
import useFeatureFlag from "app/hooks/use-feature-flag"
import useQuery from "app/hooks/use-query"
import useAppSelector from "app/hooks/useAppSelector"
import { EditPatientModal } from "app/main/patient-orders/EditPatientModal"
import { FeatureFlag } from "app/providers/FeatureFlagProvider"
import withReducer from "app/store/withReducer"
import { getNewDraftText } from "app/utils"
import makeAppStyles from "app/utils/makeAppStyles"

import PatientDetails from "./PatientDetails"
import PatientDetailsTabs, { PatientDetailsTabId } from "./PatientDetailsTabs"
import PatientOrderModals from "./PatientOrderModals"
import PatientOrdersList from "./PatientOrdersList"
import ScheduledOrders from "./ScheduledOrders"
import PatientDocumentsList from "./patient-documents/PatientDocumentsList"
import PatientDocumentsListV2 from "./patient-documents/PatientDocumentsListV2"
import * as Actions from "./store/actions"
import reducer from "./store/reducers"
import Trends from "./trends/PractitionerTrends"

const useStyles = makeAppStyles({
  ordersContainer: {
    marginTop: 24,
  },
  startOrderButton: {
    alignItems: "center",
  },
})

function PatientOrders() {
  const styles = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()

  const { patientId } = useParams<{ patientId: string }>()
  const patient = useAppSelector(({ patient }) => patient.patient)
  const practitioner = useAppSelector(({ practitioner }) => practitioner)

  const status = query.get("status")
  const showDrafts = status === ORDER_STATUS.DRAFT
  const [editPatientModalOpen, setEditPatientModalOpen] = useState(false)

  // PatientDetailsTabs Feature Flag
  const [tabsEnabled] = useFeatureFlag(FeatureFlag.PatientDetailsTabs)

  // Use a query param to toggle the status
  const toggleStatus = useCallback(() => {
    let path = location.pathname

    if (!showDrafts) {
      path = `${path}?status=${ORDER_STATUS.DRAFT}`
    }

    history.push(path)
  }, [showDrafts, location])

  // Load the Patient on mount
  useEffect(() => {
    dispatch(Actions.getPatient(patientId))

    return () => {
      dispatch(Actions.reset())
    }
  }, [patientId, dispatch])

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))

  if (!patient.user) {
    return <Loading ariaLabel="Loading patient" />
  }

  const newDraftText = getNewDraftText(practitioner, patient.first_name)

  return (
    <div>
      <PatientOrderModals patient={patient} />
      <EditPatientModal
        patient={patient}
        onClose={() => setEditPatientModalOpen(false)}
        open={editPatientModalOpen}
      />
      <PageToolbar
        title={`${patient.full_name}`}
        subtitle={
          <PatientDetails
            patient={patient}
            onEditClick={() => setEditPatientModalOpen(true)}
          />
        }
        breadcrumbActionsSlot={
          tabsEnabled ? (
            <PatientDetailsTabs />
          ) : (
            <FoodPlanCreateForPatientButton />
          )
        }
        breadcrumbs={
          isMobile
            ? undefined
            : [
                {
                  to: UserPaths.ROOT,
                  icon: HouseGrayIcon,
                  label: "Dashboard",
                },
                {
                  label: `${patient.full_name}`,
                },
              ]
        }
        toolbarsSlot={!tabsEnabled && <PatientFoodPlanListToolbar />}
      >
        {!tabsEnabled && (
          <RupaButton size="small" color="secondary" onClick={toggleStatus}>
            {showDrafts ? "View Submitted" : "View Drafts"}
          </RupaButton>
        )}
        {newDraftText && (
          <RupaButton
            className={styles.startOrderButton}
            size="small"
            href={`/checkout/create/${patient.id}`}
            startIcon={AddIcon}
          >
            {newDraftText}
          </RupaButton>
        )}
      </PageToolbar>
      <Container
        maxWidth="xl"
        className={clsx("fs-unmask overflow-x-auto", styles.ordersContainer)}
      >
        {tabsEnabled ? (
          <PatientDetailsInner />
        ) : (
          <>
            {!showDrafts && <ScheduledOrders patient={patient} />}
            <PatientOrdersList showDrafts={showDrafts} patient={patient} />
          </>
        )}
      </Container>
    </div>
  )
}

const PatientDetailsInner: React.FC = () => {
  const patient = useAppSelector(({ patient }) => patient.patient)
  const query = useQuery()
  const activeTab = query.get("tab")

  const [practitionerDocumentsV2Enabled] = useFeatureFlag(
    FeatureFlag.PractitionerDocumentsV2
  )

  switch (activeTab) {
    case PatientDetailsTabId.DOCUMENTS:
      return practitionerDocumentsV2Enabled ? (
        <PatientDocumentsListV2 inPatientPortal={false} patient={patient} />
      ) : (
        <PatientDocumentsList patient={patient} />
      )
    case PatientDetailsTabId.RESULTS_OVER_TIME:
      return <Trends patient={patient} />
    case PatientDetailsTabId.ORDERS:
    default:
      return (
        <div className="mb-20">
          <ScheduledOrders patient={patient} />
          <PatientOrdersList showDrafts={false} patient={patient} />
        </div>
      )
  }
}

export default withReducer("patient", reducer)(PatientOrders)
