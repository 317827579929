import { useCallback, useMemo } from "react"

import { cn } from "@rupahealth/design"

import { ReactComponent as ResultsEditIcon } from "app/assets/icons/flask.svg"
import { ReactComponent as ManualPatientResultDocumentIcon } from "app/assets/images/manual-patient-result-document-icon.svg"
import BodyText from "app/components/design-system/BodyText"
import useEventCallback from "app/hooks/use-event-callback"
import useAppSelector from "app/hooks/useAppSelector"
import useResultsUploadManualEntryModal from "app/main/results-upload/modals/ResultsUploadManualEntryModal"
import useResultsUploadPdfModal from "app/main/results-upload/modals/ResultsUploadPdfModal"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { PatientResultDocument } from "types/patient-with-documents"
import { User } from "types/user"

import DocumentCardActionsMenu from "./DocumentCardActionsMenu"
import PatientDocumentCard from "./PatientDocumentCard"
import PendingTrackingInTrendsSubTitle from "./PendingTrackingInTrendsSubTitle"
import TrackingInTrendsSubTitle from "./TrackingInTrendsSubTitle"

interface Props {
  patientResult: PatientResultDocument
  onDelete: (patientResult: PatientResultDocument) => void
}

const PatientResultDocumentCard: React.FC<Props> = ({
  patientResult,
  onDelete,
}) => {
  const user = useCachedResource<User>(patientResult?.relationships.user.data)
  const patient = useAppSelector(({ patient }) => patient.patient)

  const editManualPatientResultModal = useResultsUploadManualEntryModal()
  const resultsUploadPdfModal = useResultsUploadPdfModal()

  const onEdit = useEventCallback(() => {
    if (
      patientResult.attributes.file &&
      patientResult.attributes.file_type === "pdf"
    ) {
      resultsUploadPdfModal.show({
        existingUserResultId: patientResult.id,
        existingFileUrl: patientResult.attributes.file,
        patientId: patient.id,
        onClose: () => resultsUploadPdfModal.remove(),
      })
    } else {
      editManualPatientResultModal.show({
        patientId: patient.id,
        existingUserResultId: patientResult.id,
        uploadedFileUrl: patientResult.attributes.file,
        uploadedFileName: patientResult.attributes.file_name || undefined,
        onClose: () => editManualPatientResultModal.remove(),
      })
    }
  })

  const isBeingTrackedInTrends = (
    supportsResultsVisualizations: boolean,
    hasSnapshot: boolean
  ): boolean => {
    return supportsResultsVisualizations && hasSnapshot
  }

  const isPendingTrendsSetup = (
    supportsResultsVisualizations: boolean,
    hasSnapshot: boolean
  ): boolean => {
    return supportsResultsVisualizations && !hasSnapshot
  }

  const isTrackedInTrends = useMemo(() => {
    return isBeingTrackedInTrends(
      patientResult.attributes.supports_results_visualizations,
      patientResult.attributes.has_snapshot
    )
  }, [patientResult])

  const isPendingTrackingInTrends = useMemo(() => {
    return isPendingTrendsSetup(
      patientResult.attributes.supports_results_visualizations,
      patientResult.attributes.has_snapshot
    )
  }, [patientResult])

  const canDeleteData = useMemo(() => {
    return isTrackedInTrends || patientResult.attributes.source === "api"
  }, [patientResult, isTrackedInTrends])

  const canDownloadData = useMemo(() => {
    return Boolean(patientResult.attributes.file)
  }, [patientResult])

  const handleOnDelete = () => {
    onDelete(patientResult)
  }

  const handleOnDownload = () => {
    if (!canDownloadData) return

    window.open(patientResult.attributes.file!)
  }

  const subTitle = useMemo(() => {
    if (!user) return ""

    // This is not a manually entered result
    if (patientResult.attributes.file) {
      if (isTrackedInTrends) {
        return <TrackingInTrendsSubTitle title="Tracking in Trends" />
      }

      if (isPendingTrackingInTrends) {
        return (
          <PendingTrackingInTrendsSubTitle title="Pending Tracking in Trends" />
        )
      }

      return ""
    } else {
      const fullName = user.attributes.full_name
      const biomarkerCount =
        patientResult.attributes.total_biomarker_results_count

      let subtitleText = ""

      if (biomarkerCount === 0) {
        subtitleText = `Manually Imported by ${fullName}`
      } else if (biomarkerCount === 1) {
        subtitleText = `Manually Imported by ${fullName} • 1 biomarker`
      } else {
        subtitleText = `Manually Imported by ${fullName} • ${biomarkerCount} biomarkers`
      }

      if (isTrackedInTrends) {
        return (
          <div>
            <BodyText size="xs" weight="semibold">
              {subtitleText}
            </BodyText>
            <TrackingInTrendsSubTitle title="Tracking in Trends" />
          </div>
        )
      }

      if (isPendingTrackingInTrends) {
        return (
          <div>
            <BodyText size="xs" weight="semibold">
              {subtitleText}
            </BodyText>
            <PendingTrackingInTrendsSubTitle title="Pending Tracking in Trends" />
          </div>
        )
      }

      return subtitleText
    }
  }, [patientResult, user])

  const Icon = useCallback(
    (className: string) => {
      if (patientResult.attributes.latest_thumbnail) {
        return (
          <img
            className={cn(className, "w-[90px] h-[111px]")}
            src={patientResult.attributes.latest_thumbnail}
            alt="Report Thumbnail"
          />
        )
      }
      return (
        <ManualPatientResultDocumentIcon
          className={"min-w-[90px] shadow-xl rounded-sm"}
        />
      )
    },
    [patientResult]
  )

  return (
    <PatientDocumentCard
      onClick={onEdit}
      Icon={Icon}
      preTitle={patientResult.attributes.lab_company_results_name?.toUpperCase()}
      title="Lab Results Data"
      subTitle={subTitle}
      date={
        patientResult.attributes.collection_date ??
        patientResult.attributes.received_date
      }
      actions={
        <DocumentCardActionsMenu
          onDownload={canDownloadData ? handleOnDownload : undefined}
          onDelete={canDeleteData ? handleOnDelete : undefined}
          onEdit={onEdit}
          editIcon={<ResultsEditIcon />}
          editLabel={
            isPendingTrackingInTrends
              ? "Prepare to Track in Trends"
              : "Edit Data"
          }
          deleteLabel="Remove Data"
        />
      }
    />
  )
}

export default PatientResultDocumentCard
