import { createContext, useContext } from "react"

import { useModal } from "@ebay/nice-modal-react"

import {
  ClinicAccessModalDocument,
  UserClinicAccessSharingStatus,
} from "app/types"
import { Clinic } from "types/clinic"

import useClinicDocumentAccess from "../hooks/use-clinic-document-access"

interface ContextProps {
  userId: string
  clinic: Clinic
  modalOpen: boolean
  closeModal: () => void
  isSubmitting: boolean
  isLoading: boolean
  sharingStatus: UserClinicAccessSharingStatus | null
  sharingFuture: boolean
  documents: ClinicAccessModalDocument[]
  setDocument: (id: string, canRead: boolean) => void
  setSharingStatus: (sharingStatus: UserClinicAccessSharingStatus) => void
  setSharingFuture: (sharingFuture: boolean) => void
  onSubmit: () => void
}

const ClinicDocumentAccessModalContext = createContext<ContextProps>(
  undefined as never
)

export interface ClinicDocumentAccessModalProviderProps {
  userId: string
  clinic: Clinic
  children: React.ReactNode
}

function ClinicDocumentAccessModalProvider({
  userId,
  clinic,
  children,
}: ClinicDocumentAccessModalProviderProps) {
  const modal = useModal()
  const {
    documents,
    sharingStatus,
    sharingFuture,
    isLoading,
    isSubmitting,
    setSharingStatus,
    setDocument,
    setSharingFuture,
    saveData,
  } = useClinicDocumentAccess(userId, clinic.id)

  const closeModal = () => {
    modal.remove()
  }

  const onSubmit = async () => {
    const success = await saveData()
    if (success) closeModal()
  }

  return (
    <ClinicDocumentAccessModalContext.Provider
      value={{
        userId,
        clinic,
        modalOpen: modal.visible,
        isSubmitting,
        closeModal,
        documents,
        sharingStatus,
        sharingFuture,
        isLoading,
        setSharingStatus,
        setDocument,
        setSharingFuture,
        onSubmit,
      }}
    >
      {children}
    </ClinicDocumentAccessModalContext.Provider>
  )
}

export function useClinicDocumentAccessModalContext() {
  return useContext(ClinicDocumentAccessModalContext)
}

export default ClinicDocumentAccessModalProvider
