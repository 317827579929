import { useRef, useState } from "react"

import Lottie, { LottieRefCurrentProps } from "lottie-react"

import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, cn } from "@rupahealth/design"

import { ReactComponent as FileWithLinesIcon } from "app/assets/images/results-over-time/file-with-lines.svg"
import BodyText from "app/components/design-system/BodyText"
import BaseFileInput from "app/components/upload/BaseFileInput"
import Dropzone from "app/components/upload/dropzone/Dropzone"
import { humanFileType } from "app/components/upload/helpers"

import resultsFilesAnimation from "../../assets/results-files-lottie.json"
import useResultsUploadManualEntryModal from "../ResultsUploadManualEntryModal"

interface Props {
  patientId: string
  onUploadComplete: (file: File) => void
  onClose: () => void
}

const ResultsUploadPdfUploadCard = ({
  patientId,
  onUploadComplete,
  onClose,
}: Props) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)
  const lottieNonPdfRef = useRef<LottieRefCurrentProps | null>(null)

  const fileInputRef = useRef<any>(null)

  const [currSegment, setCurrSegment] = useState([0, 60])
  const [file, setFile] = useState<File | undefined>(undefined)
  const [lockUpload, setLockUpload] = useState(false)
  const [nonPdfFileUploaded, setNonPdfFileUploaded] = useState(false)

  const resultsUploadManualEntryModal = useResultsUploadManualEntryModal()

  const openFileDialog = () => {
    fileInputRef.current.click()
  }

  const handleOnComplete = () => {
    if (currSegment[0] === 60 && file) {
      onUploadComplete(file)
      setLockUpload(true)
    }
  }

  const handleFilesAdded = (files) => {
    // We only care about the first file as we do not support multiple files
    const file = files[0]

    if (!file.name.includes(".pdf")) {
      setFile(file)
      setNonPdfFileUploaded(true)
    } else {
      setFile(file)
      if (lottieRef.current && !nonPdfFileUploaded) {
        setCurrSegment([60, 157])
        lottieRef.current.playSegments([60, 157])
      } else if (lottieNonPdfRef.current && nonPdfFileUploaded) {
        setCurrSegment([60, 157])
        lottieNonPdfRef.current.playSegments([60, 157])
      }
    }
  }

  return nonPdfFileUploaded ? (
    <div className="bg-white sm:py-[29px] sm:px-[75px] p-5 flex flex-col items-center gap-[18px] rounded-xl border-2 border-dashed border-slate-300">
      <Lottie
        animationData={resultsFilesAnimation}
        width={250}
        loop={false}
        initialSegment={[59, 60]}
        lottieRef={lottieNonPdfRef}
        onComplete={handleOnComplete}
      />
      <div
        className={cn(
          "flex flex-col items-start gap-[9px] w-full transition-opacity duration-500",
          currSegment[0] === 60 ? "opacity-0" : "opacity-100"
        )}
      >
        <div className="flex flex-col gap-1 rounded-lg bg-sky-50 border border-sky-300 p-3">
          <div className="flex gap-[10px] items-center">
            <div>
              <FileWithLinesIcon />
            </div>
            <BodyText weight="semibold">{file?.name} is not a PDF</BodyText>
          </div>
          <BodyText>
            Rupa automatically extracts results from PDFs for you. Continue with
            this {humanFileType(file)} file and enter results manually, or
            upload a PDF version if you have it. Your call!
          </BodyText>
        </div>
        <div className="flex sm:flex-row flex-col gap-3 w-full mt-[18px]">
          <Button
            variant="outline-primary"
            className="w-full"
            onClick={openFileDialog}
          >
            Upload PDF
            <input
              ref={fileInputRef}
              type="file"
              onChange={(e) => handleFilesAdded(e.target.files)}
              accept=".pdf"
              className="FileInput hidden"
            />
          </Button>
          <Button
            variant="primary"
            className="w-full"
            onClick={() => {
              resultsUploadManualEntryModal.show({
                patientId,
                uploadedFile: file,
                onClose: () => resultsUploadManualEntryModal.remove(),
              })
              onClose()
            }}
          >
            Enter Manually
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <BaseFileInput onFilesAdded={handleFilesAdded}>
      {(props) => (
        <Dropzone
          {...props}
          className="py-16 sm:px-[75px] px-[25px]"
          disabled={lockUpload}
          hideUploadLogo={true}
        >
          <div className="flex flex-col items-center gap-[18px]">
            <Lottie
              animationData={resultsFilesAnimation}
              width={250}
              loop={false}
              initialSegment={[0, 60]}
              lottieRef={lottieRef}
              onComplete={handleOnComplete}
            />
            <div
              className={cn(
                "flex flex-col items-center gap-[9px] w-full transition-opacity duration-500",
                file ? "opacity-0" : "opacity-100"
              )}
            >
              <Button
                variant="primary"
                className="w-full"
                disabled={lockUpload}
              >
                <FontAwesomeIcon icon={faPlus} className="text-white mr-2" />{" "}
                Upload Results PDF
              </Button>
              <BodyText>or</BodyText>
              <Button
                disabled={lockUpload}
                variant="ghost"
                className="text-primary p-0 m-0 h-auto w-full hover:bg-transparent"
                onClick={(e) => {
                  resultsUploadManualEntryModal.show({
                    patientId,
                    onClose: () => resultsUploadManualEntryModal.remove(),
                  })
                  onClose()
                  e.stopPropagation()
                }}
              >
                Enter Results Manually
              </Button>
            </div>
          </div>
        </Dropzone>
      )}
    </BaseFileInput>
  )
}

export default ResultsUploadPdfUploadCard
