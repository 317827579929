import { Checkbox } from "@material-ui/core"

import { ReactComponent as CheckIcon } from "app/assets/icons/check-square.svg"
import { ReactComponent as UncheckedIcon } from "app/assets/icons/empty-square.svg"
import { blueGray, primaryColor, white } from "app/theme"
import { ClinicAccessModalDocument } from "app/types"

import { useClinicDocumentAccessModalContext } from "./ClinicDocumentAccessModalProvider"

const ClinicDocumentAccessDocument = ({
  document,
}: {
  document: ClinicAccessModalDocument
}) => {
  const { setDocument } = useClinicDocumentAccessModalContext()

  const onCheckboxClick = () => {
    setDocument(document.id, !document.can_read)
  }
  const checkBoxDisabled = document.is_owner

  return (
    <div className="flex flex-row gap-2">
      <Checkbox
        icon={
          <UncheckedIcon
            viewBox="0 0 21 21"
            width={20}
            height={20}
            fill={white}
          />
        }
        checkedIcon={
          <CheckIcon
            fill={checkBoxDisabled ? blueGray : primaryColor}
            viewBox="0 0 21 21"
            width={20}
            height={20}
          />
        }
        checked={document.can_read}
        disabled={checkBoxDisabled}
        onChange={onCheckboxClick}
      />
      <div className="flex flex-col justify-center">
        <p className="font-semibold text-sm">{document.title}</p>
        <p className="font-normal text-sm">{document.subtitle}</p>
      </div>
    </div>
  )
}

export default ClinicDocumentAccessDocument
