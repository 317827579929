import { useMemo } from "react"

import { orderBy } from "lodash"
import { useHistory } from "react-router-dom"

import { PaginationState } from "@tanstack/react-table"

import { convertPaginationToParams } from "app/dashboard/utils"
import { getFoodPlanPath } from "app/food-plans/utils/paths"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useQuery from "app/hooks/use-query"
import useCachedCollection from "app/swr/hooks/use-cached-collection"
import useCollectionSWR from "app/swr/hooks/use-collection-swr"
import { ResourceCollection, ResourceResponse } from "app/swr/types"
import resourceRequest from "app/swr/utils/resource-request"
import { FoodPlan, FoodPlanCreate } from "types/food-plan"
import {
  Document,
  PatientDocumentType,
  PaginationMeta,
} from "types/patient-with-documents-v2"

import {
  DOCUMENT_TYPE_QUERY_PARAM_KEY,
  DocumentTypeToResourceTypeMapping,
  getDocumentParamValueOption,
  getDocumentTypeOption,
} from "../DocumentTypeSelectV2"
import useDeleteDocumentConfirmModal from "./use-delete-document-confirm-modal"

interface Props {
  patientId: string
  patientUserId?: string
  patientFirstName: string
  pagination: PaginationState
}

export function usePatientDocuments({
  patientId,
  patientUserId,
  patientFirstName,
  pagination,
}: Props) {
  const handleApiError = useHandleApiError()

  const history = useHistory()
  const query = useQuery()

  // Filter the results
  const documentType = query.get(DOCUMENT_TYPE_QUERY_PARAM_KEY)

  // Map the document type to the new resource_type
  const resourceType = documentType
    ? DocumentTypeToResourceTypeMapping[documentType]
    : ""

  const paginationParams = useMemo(
    () => convertPaginationToParams(pagination),
    [pagination]
  )

  const {
    data: documentIdentifiers,
    meta,
    mutate,
    ...patientSwr
  } = useCollectionSWR<ResourceCollection<Document>>(
    "/documents/",
    {
      include: ["user_result", "food_plan", "results_interpretation"],
      params: {
        ...paginationParams,
        resource_type: resourceType,
        ...(patientUserId && { "filter[user.id]": patientUserId }),
      },
    },
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )

  const onCreateFoodPlan = useEventCallback(async () => {
    try {
      const { data } = await resourceRequest<
        ResourceResponse<FoodPlan>,
        FoodPlanCreate
      >({
        method: "post",
        url: "/food_plans/",
        include: ["latest_version"],
        data: {
          data: {
            type: "food_plan",
            relationships: {
              patient: {
                data: {
                  type: "patient",
                  id: patientId,
                },
              },
            },
          },
        },
      })

      history.push(getFoodPlanPath(patientId, data.id))
    } catch (error) {
      handleApiError(error)
    }
  })

  const onDeleteFoodPlan = useEventCallback(async (foodPlan: Document) => {
    try {
      console.log("onDeleteFoodPlan", foodPlan)

      await mutate(
        async (previousData) => {
          if (!previousData) return previousData

          const foodPlanId = foodPlan.relationships.food_plan?.data.id
          await resourceRequest({
            url: `/food_plans/${foodPlanId}/`,
            method: "delete",
          })

          return {
            ...previousData,
            data: previousData.data.filter((doc) => doc.id !== foodPlan.id),
          }
        },
        { revalidate: false, throwOnError: true }
      )
    } catch (error) {
      handleApiError(error)
    }
  })

  const onDeleteResultInterpretations = useEventCallback(
    async (resultsSummary: Document) => {
      try {
        await mutate(
          async (previousData) => {
            if (!previousData) return previousData

            const resultsSummaryId =
              resultsSummary.relationships.results_interpretation?.data.id
            await resourceRequest({
              url: `/results_interpretations/${resultsSummaryId}/`,
              method: "delete",
            })

            return {
              ...previousData,
              data: previousData.data.filter(
                (doc) => doc.id !== resultsSummary.id
              ),
            }
          },
          { revalidate: false, throwOnError: true }
        )
      } catch (error) {
        handleApiError(error)
      }
    }
  )

  const onDeleteUserResult = useEventCallback(
    async (patientResult: Document) => {
      try {
        await mutate(
          async (previousData) => {
            if (!previousData) return previousData

            const patientResultId =
              patientResult.relationships.user_result?.data.id
            await resourceRequest({
              method: "delete",
              url: `/user_results/${patientResultId}/`,
            })

            return {
              ...previousData,
              data: previousData.data.filter(
                (doc) => doc.id !== patientResult.id
              ),
            }
          },
          { revalidate: false, throwOnError: true }
        )
      } catch (error) {
        handleApiError(error)
      }
    }
  )

  const deleteDocument = async (document: Document) => {
    if (document.attributes.resource_type === PatientDocumentType.FOOD_PLAN) {
      onDeleteFoodPlan(document)
    } else if (
      document.attributes.resource_type ===
      PatientDocumentType.RESULTS_INTERPRETATION
    ) {
      onDeleteResultInterpretations(document)
    } else if (
      document.attributes.resource_type ===
      PatientDocumentType.IMPORTED_USER_RESULT
    ) {
      onDeleteUserResult(document)
    }
  }

  const deleteDocumentConfirmModal = useDeleteDocumentConfirmModal()
  const onDocumentDelete = (document) => {
    deleteDocumentConfirmModal.show({
      patientName: patientFirstName,
      documentTitle: getDocumentTypeOption(document.attributes.resource_type)
        ?.label,
      onDelete: () => deleteDocument(document),
    })
  }

  const documentTypeOption = getDocumentParamValueOption(documentType)

  const cachedDocuments = useCachedCollection<Document>(documentIdentifiers)

  const sortedDocuments = useMemo(
    () => orderBy(cachedDocuments, "attributes.created_at", "desc"),
    [cachedDocuments]
  )

  return {
    ...patientSwr,
    data: sortedDocuments,
    totalDocumentCount: (meta as PaginationMeta)?.pagination.count || 0,
    selectedDocumentType: documentTypeOption,
    onCreateFoodPlan,
    onDocumentDelete,
  }
}
