import React, { useEffect, useState } from "react"

import NiceModal, { muiDialog, useModal } from "@ebay/nice-modal-react"
import {
  CircularProgress,
  FadeProps,
  Grow,
  IconButton,
  Link,
} from "@material-ui/core"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import shadows from "@material-ui/core/styles/shadows"
import CloseIcon from "@material-ui/icons/Close"
import { cn } from "@rupahealth/design"

import { ReactComponent as LabReportIcon } from "app/assets/images/results-over-time/lab-report.svg"
import { ReactComponent as ResultFileIcon } from "app/assets/images/results-over-time/result-file.svg"
import BetaChip from "app/components/design-system/BetaChip"
import BodyText from "app/components/design-system/BodyText"
import { Dialog } from "app/components/modals"
import useConfirmationModal from "app/components/modals/generic/hooks/use-confirmation-modal"
import ResultsUploadManualEntryLoadingState from "app/main/results-upload/modals/manual-upload/ResultsUploadManualEntryLoadingState"
import { colors } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import useCreateUserResult from "../hooks/use-create-user-result"
import UserResultProvider, {
  useUserResultContext,
} from "../providers/UserResultDataProvider"
import ContentCard from "./components/ContentCard"
import UserResultFormContainer from "./components/UserResultFormContainer"
import ResultsUploadPdfAllSetCard from "./pdf-upload/ResultsUploadPdfAllSetCard"

interface Props {
  patientId: string
  existingUserResultId?: string
  uploadedFile?: File
  uploadedFileUrl?: string
  uploadedFileName?: string
  onClose: () => void
}

/**
 * Wrapper for the modal that provides the data context for the user result
 */
const ResultsUploadManualEntryModal = ({
  existingUserResultId,
  ...innerProps
}: Props) => {
  const [userResultId, setUserResultId] = useState(existingUserResultId)

  return (
    <UserResultProvider userResultId={userResultId}>
      <ResultsUploadManualEntryModalInner
        {...innerProps}
        userResultId={userResultId}
        setUserResultId={setUserResultId}
      />
    </UserResultProvider>
  )
}

interface InnerProps extends Omit<Props, "existingUserResultId"> {
  userResultId: string | undefined
  setUserResultId(userResultId: string | undefined): void
}

const GrowTransition = React.forwardRef<unknown, FadeProps>(function Transition(
  props,
  ref
) {
  return <Grow ref={ref} {...props} timeout={500} />
})

const useStyles = makeAppStyles<{ showAllSetCard: boolean }>(() => ({
  dialogPaper: {
    margin: 15,
    borderRadius: 12,
    border: "4px solid white",
    boxShadow: shadows["2xl"],
    width: (props) => (props.showAllSetCard ? 580 : "100%"),
    transition: "all 500ms",
  },
}))

const ResultsUploadManualEntryModalInner = ({
  patientId,
  uploadedFile,
  uploadedFileUrl,
  uploadedFileName,
  userResultId,
  setUserResultId,
  onClose,
}: InnerProps) => {
  const [showAllSetCard, setShowAllSetCard] = useState(false)

  const classes = useStyles({ showAllSetCard })

  const modal = useModal()
  const muiDialogControl = muiDialog(modal)

  const {
    userResult,
    userResultSWR: {
      isLoading: isUserResultLoading,
      isValidating: isUserResultValidating,
      error: userResultError,
    },
    userBiomarkerResultsSWR: {
      isLoading: isUserBiomarkerResultsLoading,
      isValidating: isUserBiomarkerResultsValidating,
      error: userBiomarkerResultError,
    },
  } = useUserResultContext()

  const { createUserResult, isCreating } = useCreateUserResult()

  const confirmationModal = useConfirmationModal()

  const handleSnapshotCreate = () => {
    setShowAllSetCard(true)
  }

  const confirmClose = () => {
    confirmationModal.show({
      title: "Are You Sure?",
      message: (
        <BodyText>If you exit now, your results won't be saved.</BodyText>
      ),
      backButtonTitle: "Back to Review",
      confirmButtonTitle: "Exit Without Saving",
      confirmButtonColor: "destructiveSecondary",
      onClose: confirmationModal.remove,
      handleConfirm: async () => {
        onClose()
        confirmationModal.remove()
      },
      loading: false,
      maxWidth: "xs",
      fullWidthButtons: true,
    })
  }

  useEffect(() => {
    if (
      !userResultId &&
      !isUserResultLoading &&
      !isUserBiomarkerResultsLoading &&
      !userResultError
    ) {
      createUserResult({
        patientId: patientId,
      }).then((newId) => {
        if (newId) {
          setUserResultId(newId)
        }
      })
    }
  }, [userResult, isUserResultLoading, isUserBiomarkerResultsLoading])

  if (userResultError || userBiomarkerResultError) {
    return (
      <Dialog
        {...muiDialogControl}
        disableEnforceFocus
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        className={"fs-unmask"}
        classes={{
          paper: classes.dialogPaper,
        }}
        TransitionComponent={GrowTransition}
      >
        <TitleSection
          onClose={showAllSetCard ? onClose : confirmClose}
          title="Manually Enter Results"
          isValidating={false}
        />
        <MuiDialogContent className="sm:p-[22.5px] bg-gray-100 flex flex-col gap-4 p-3">
          <ContentCard>
            Oh no! Something went wrong! Please try again.
          </ContentCard>
        </MuiDialogContent>
      </Dialog>
    )
  }

  return (
    <>
      <Dialog
        {...muiDialogControl}
        disableEnforceFocus
        disableEscapeKeyDown
        fullWidth
        maxWidth={"md"}
        className={"fs-unmask"}
        classes={{
          paper: classes.dialogPaper,
        }}
        TransitionComponent={GrowTransition}
      >
        {showAllSetCard ? (
          <div className="flex justify-between mb-[18px] mt-5 mx-5">
            <BodyText size="lg" weight="semibold" className="font-title">
              Import Lab Results{" "}
              <BetaChip
                backgroundColor={colors.teal[100]}
                color={colors.teal[700]}
                className="ml-1"
              />
            </BodyText>

            <IconButton
              aria-label="close"
              onClick={onClose}
              key="close-button"
              className="text-body p-0 m-0"
            >
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <TitleSection
            title="Manually Enter Results"
            onClose={confirmClose}
            isValidating={
              isUserResultValidating || isUserBiomarkerResultsValidating
            }
          />
        )}
        {(isUserResultLoading || isCreating) && (
          <MuiDialogContent className="sm:p-[22.5px] bg-gray-100 flex flex-col gap-4 p-3">
            <ContentCard>
              <ResultsUploadManualEntryLoadingState />
            </ContentCard>
          </MuiDialogContent>
        )}

        {showAllSetCard ? (
          <div className="px-[22.5px] pb-[22.5px]">
            <ResultsUploadPdfAllSetCard onClose={onClose} />
          </div>
        ) : (
          <div
            className={cn(
              "px-[22.5px] pb-[22.5px] bg-gray-100",
              isUserResultLoading || isCreating
                ? "opacity-0 h-0"
                : "opacity-100 h-full"
            )}
          >
            {(uploadedFile || (uploadedFileUrl && uploadedFileName)) && (
              <div className="w-full rounded-lg border border-sky-300 bg-sky-50 flex gap-[10px] mt-[22.5px] p-3 items-center">
                <ResultFileIcon />
                <BodyText weight="semibold">
                  <Link
                    className="cursor-pointer"
                    href={uploadedFileUrl}
                    target="_blank"
                  >
                    {uploadedFile ? uploadedFile["name"] : uploadedFileName}
                  </Link>
                </BodyText>
              </div>
            )}

            {!isCreating && (
              <div className="pt-5 min-h-3.5">
                <UserResultFormContainer
                  userResultId={userResultId || ""}
                  onSnapshotCreate={handleSnapshotCreate}
                  isManualEntry={true}
                />
              </div>
            )}
          </div>
        )}
      </Dialog>
    </>
  )
}

const TitleSection = ({ title, onClose, isValidating }) => {
  const closeButton = onClose && (
    <IconButton
      aria-label="close"
      onClick={onClose}
      key="close-button"
      className="text-body relative top-[1px] right-0 mr-[-12px]"
    >
      <CloseIcon />
    </IconButton>
  )

  return (
    <MuiDialogTitle
      disableTypography
      className="flex justify-between flex-nowrap bg-white items-start py-4 px-6"
    >
      <div className="flex gap-5 items-center">
        <div className="w-[73px]">
          <LabReportIcon />
        </div>

        <div className="flex flex-col gap-1">
          <BodyText
            weight="semibold"
            size="lg"
            className="font-title flex items-center"
          >
            {title}{" "}
            <BetaChip
              backgroundColor={colors.teal[100]}
              color={colors.teal[700]}
              className="ml-1 relative top-[-2px]"
            />{" "}
            {isValidating && <CircularProgress size={16} />}
          </BodyText>
          <BodyText>
            You'll always be able to edit these later in Documents.
          </BodyText>
        </div>
      </div>
      {closeButton}
    </MuiDialogTitle>
  )
}

const ResultsUploadManualEntryNiceModal = NiceModal.create<Props>(
  ResultsUploadManualEntryModal
)

export default function useResultsUploadManualEntryModal() {
  return useModal(ResultsUploadManualEntryNiceModal)
}
