import { Button } from "@rupahealth/design"

import { ReactComponent as FlaskIcon } from "app/assets/icons/flask.svg"
import useResultsUploadPdfModal from "app/main/results-upload/modals/ResultsUploadPdfModal"
import { primaryColor } from "app/theme"

interface Props {
  patientId: string
  existingUserResultId?: string
  existingOrderedResultId?: string
  buttonText?: string
  handleOnClick?: () => void
}

const ResultsOverTimeImportButton = ({
  patientId,
  buttonText,
  existingUserResultId,
  existingOrderedResultId,
  handleOnClick,
}: Props) => {
  const resultsUploadPdfModal = useResultsUploadPdfModal()

  return (
    <Button
      variant={null}
      className="text-primary max-md:px-0 max-md:justify-start hover:bg-slate-100 py-1 px-2 h-auto text-[15px]"
      onClick={() => {
        if (existingUserResultId) {
          resultsUploadPdfModal.show({
            onClose: () => resultsUploadPdfModal.remove(),
            existingUserResultId,
            patientId: patientId,
          })
        } else {
          resultsUploadPdfModal.show({
            onClose: () => resultsUploadPdfModal.remove(),
            existingOrderedResultId,
            patientId: patientId,
          })
        }

        if (handleOnClick) {
          handleOnClick()
        }
      }}
    >
      <FlaskIcon className="mr-2" fill={primaryColor} />{" "}
      {buttonText ?? "Import Results"}
    </Button>
  )
}

export default ResultsOverTimeImportButton
