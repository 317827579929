import { useEffect, useState } from "react"

import * as Sentry from "@sentry/react"

import { API } from "app/api"
import useEventCallback from "app/hooks/use-event-callback"
import useHandleApiError from "app/hooks/use-handle-api-error"
import useHandleApiSuccess from "app/hooks/use-handle-api-success"
import {
  ClinicAccessModalData,
  ClinicAccessModalDocument,
  UserClinicAccessSharingStatus,
} from "app/types"

export default function useClinicDocumentAcess(
  userId: string,
  clinicId: string
) {
  const [isLoading, setIsLoading] = useState(false)
  const [sharingStatus, setSharingStatus] =
    useState<UserClinicAccessSharingStatus | null>(null)
  const [sharingFuture, setSharingFuture] = useState(false)
  const [documents, setDocuments] = useState<ClinicAccessModalDocument[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleApiSuccess = useHandleApiSuccess()
  const handleApiError = useHandleApiError()

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true)
      const { data } = await API.User.getClinicDocumentAccess(userId, clinicId)
      setIsLoading(false)

      const clinicModalData = data as ClinicAccessModalData
      if (
        clinicModalData.sharing_status ===
        UserClinicAccessSharingStatus.SPECIFIC_AND_FUTURE
      ) {
        setSharingStatus(UserClinicAccessSharingStatus.SPECIFIC)
        setSharingFuture(true)
      } else {
        setSharingStatus(clinicModalData.sharing_status)
      }
      setDocuments(clinicModalData.documents)
    }

    getData()
  }, [])

  const setDocument = (id: string, canRead: boolean) => {
    setDocuments(
      documents.map((doc) =>
        doc.id === id ? { ...doc, can_read: canRead } : doc
      )
    )
  }

  const saveData = useEventCallback(async () => {
    const apiSharingStatus =
      sharingStatus === UserClinicAccessSharingStatus.SPECIFIC && sharingFuture
        ? UserClinicAccessSharingStatus.SPECIFIC_AND_FUTURE
        : sharingStatus
    const data = {
      sharing_status: apiSharingStatus,
      documents: documents,
    }
    setIsSubmitting(true)
    try {
      await API.User.patchClinicDocumentAccess(userId, clinicId, data)
      setIsSubmitting(false)
      handleApiSuccess("Document access updated")
      return true
    } catch (error) {
      setIsSubmitting(false)
      Sentry.captureException(error)
      handleApiError(error)
      return false
    }
  })

  return {
    documents,
    sharingStatus,
    sharingFuture,
    isLoading,
    setSharingStatus,
    setDocument,
    setSharingFuture,
    isSubmitting,
    saveData,
  }
}
