import { useCallback, useState } from "react"

import { Grid, TablePagination } from "@material-ui/core"
import { Skeleton } from "@rupahealth/design"
import { PaginationState } from "@tanstack/react-table"

import BodyText from "app/components/design-system/BodyText"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import { PatientPortalUser } from "app/patient-portal/types"
import { Patient } from "app/types"
import { Document, PatientDocumentType } from "types/patient-with-documents-v2"

import ResultVisualiztionNotifications from "../trends/components/ResultVisualizationNotifications"
import ResultsOverTimeImportButton from "../trends/components/TrendsImportButton"
import DocumentTypeSelect from "./DocumentTypeSelectV2"
import FoodPlanCreateButton from "./FoodPlanCreateButton"
import FoodPlanDocumentCard from "./FoodPlanDocumentCardV2"
import ImportedUserResultDocumentCard from "./ImportedUserResultDocumentCardV2"
import OrderUserResultDocumentCard from "./OrderUserResultDocumentCardV2"
import PatientDocumentsListEmpty from "./PatientDocumentsListEmpty"
import ResultsInterpretationDocumentCard from "./ResultsInterpretationDocumentCardV2"
import { usePatientDocuments } from "./hooks/use-patient-documents-v2"

interface Props {
  inPatientPortal: boolean
  patient?: Patient
  user?: PatientPortalUser
}

const PatientDocumentsList: React.FC<Props> = ({
  inPatientPortal,
  patient,
  user,
}) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 18,
  })
  const [isResultsOverTimeManualUploadEnabled] = useFeatureFlag(
    FeatureFlag.ResultsOverTimeManualResultsUpload
  )
  const [showResultVisualizationNotifications] = useFeatureFlag(
    FeatureFlag.ShowResultVisualizationNotifications
  )

  const patientId = inPatientPortal
    ? user?.relationships.patient.data?.[0]?.id ?? ""
    : patient?.id ?? ""
  const patientUserId = inPatientPortal
    ? user?.id ?? ""
    : patient?.user.id ?? ""
  const patientFirstName = inPatientPortal
    ? user?.attributes.first_name ?? ""
    : patient?.first_name ?? ""

  const {
    data: sortedDocuments,
    isLoading,
    totalDocumentCount,
    selectedDocumentType,
    onCreateFoodPlan,
    onDocumentDelete,
  } = usePatientDocuments({
    patientId: patientId,
    patientUserId: patientUserId,
    patientFirstName: patientFirstName,
    pagination,
  })

  const DocumentCard = useCallback(
    (document: Document) => {
      switch (document.attributes.resource_type) {
        case PatientDocumentType.FOOD_PLAN:
          return (
            <FoodPlanDocumentCard
              patientId={patientId}
              document={document}
              onDelete={onDocumentDelete}
            />
          )
        case PatientDocumentType.ORDER_USER_RESULT:
          return (
            <OrderUserResultDocumentCard
              patientId={patientId}
              document={document}
            />
          )
        case PatientDocumentType.RESULTS_INTERPRETATION:
          return (
            <ResultsInterpretationDocumentCard
              document={document}
              onDelete={onDocumentDelete}
            />
          )
        case PatientDocumentType.IMPORTED_USER_RESULT:
          return (
            <ImportedUserResultDocumentCard
              patientId={patientId}
              document={document}
              onDelete={onDocumentDelete}
            />
          )
      }
    },
    [onDocumentDelete, patient]
  )

  const handlePageChange = (newPageIndex: number) => {
    setPagination((prev) => ({ ...prev, pageIndex: newPageIndex }))
  }

  return (
    <div className="flex flex-col">
      {showResultVisualizationNotifications && !inPatientPortal && (
        <div className="mb-5">
          <ResultVisualiztionNotifications patientId={patientId} />
        </div>
      )}

      <div className="rounded-lg p-7 bg-white shadow">
        {/* dont show the header in case it's pract mode (when patient is provided) because in patient  portal buttons 
        should be hidden for now and later will be located outside of this compoenent in top page header  */}
        {!inPatientPortal && (
          <div className="flex md:flex-row flex-col md:justify-between md:items-center max-md:gap-2 mb-4 pb-2 border-b-[1px] border-slate-200">
            <div>
              <BodyText size="lg" weight="semibold">
                {patientFirstName}'s Documents
              </BodyText>
            </div>
            <div className="max-md:justify-start max-md:-ml-1 md:-mr-1 flex items-center">
              <FoodPlanCreateButton onCreate={onCreateFoodPlan} />
              {isResultsOverTimeManualUploadEnabled && (
                <>
                  {/* Divider */}
                  <div className="bg-slate-200 h-[25px] w-[2px] mx-2"></div>
                  <ResultsOverTimeImportButton patientId={patientId} />
                </>
              )}
            </div>
          </div>
        )}

        <div className="mb-4">
          <DocumentTypeSelect disabled={isLoading} />
        </div>

        {isLoading ? (
          <LoadingGrid />
        ) : sortedDocuments.length ? (
          <Grid container spacing={2}>
            {sortedDocuments.map((document) => (
              <Grid item key={document.id} xs={12} sm={12} md={6} lg={4}>
                {DocumentCard(document)}
              </Grid>
            ))}
          </Grid>
        ) : (
          <PatientDocumentsListEmpty
            patientFirstName={patientFirstName}
            totalDocumentCount={totalDocumentCount}
            selectedDocumentType={selectedDocumentType}
          />
        )}
      </div>
      <TablePagination
        component="div"
        className="mt-2 mb-20"
        count={totalDocumentCount}
        page={pagination.pageIndex}
        onChangePage={(_, newPage) => handlePageChange(newPage)}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[]}
      />
    </div>
  )
}

const LoadingGrid = () => {
  return (
    <Grid container spacing={2}>
      {Array.from(Array(6).keys()).map((idx) => (
        <Grid item key={idx} xs={12} sm={12} md={6} lg={4}>
          <div className="flex items-center rounded-lg p-3 gap-3.5">
            <Skeleton className="h-[110px] min-w-[90px]" />
            <div className="space-y-5 w-full">
              <Skeleton className="h-4 w-2/5" />
              <div className="space-y-2">
                <Skeleton className="h-4 w-3/4" />
                <Skeleton className="h-3 w-1/2" />
              </div>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  )
}

export default PatientDocumentsList
