import { useCallback, useMemo } from "react"

import { cn } from "@rupahealth/design"

import { ReactComponent as ResultsEditIcon } from "app/assets/icons/flask.svg"
import { ReactComponent as ManualPatientResultDocumentIcon } from "app/assets/images/manual-patient-result-document-icon.svg"
import useEventCallback from "app/hooks/use-event-callback"
import useResultsUploadManualEntryModal from "app/main/results-upload/modals/ResultsUploadManualEntryModal"
import useResultsUploadPdfModal from "app/main/results-upload/modals/ResultsUploadPdfModal"
import useCachedResource from "app/swr/hooks/use-cached-resource"
import { Document } from "types/patient-with-documents-v2"
import { UserResult } from "types/user-result"

import DocumentCardActionsMenu from "./DocumentCardActionsMenu"
import PatientDocumentCard from "./PatientDocumentCard"

interface Props {
  patientId: string
  document: Document
  onDelete: (document: Document) => void
}

const ImportedUserResultDocumentCard: React.FC<Props> = ({
  patientId,
  document,
  onDelete,
}) => {
  const userResult = useCachedResource<UserResult>(
    document.relationships.user_result?.data
  )

  const editManualPatientResultModal = useResultsUploadManualEntryModal()
  const resultsUploadPdfModal = useResultsUploadPdfModal()

  const isBeingTrackedInTrends = (
    supportsResultsVisualizations: boolean,
    hasSnapshot: boolean
  ): boolean => {
    return supportsResultsVisualizations && hasSnapshot
  }

  const isPendingTrendsSetup = (
    supportsResultsVisualizations: boolean,
    hasSnapshot: boolean
  ): boolean => {
    return supportsResultsVisualizations && !hasSnapshot
  }

  const isTrackedInTrends = useMemo(() => {
    return isBeingTrackedInTrends(
      userResult?.attributes.supports_trends_visualization ?? false,
      userResult?.attributes.has_snapshot ?? false
    )
  }, [document])

  const isPendingTrackingInTrends = useMemo(() => {
    return isPendingTrendsSetup(
      userResult?.attributes.supports_trends_visualization ?? false,
      userResult?.attributes.has_snapshot ?? false
    )
  }, [document])

  const canDownload = document.meta.can_read && userResult?.attributes.file
  const canEdit = document.meta.can_write
  const canDelete = useMemo(() => {
    return (
      document.meta.can_delete &&
      (isTrackedInTrends || userResult?.attributes.source === "api")
    )
  }, [document, isTrackedInTrends])

  const onEdit = useEventCallback(() => {
    if (
      canEdit &&
      userResult?.attributes.file &&
      userResult?.attributes.file_type === "pdf"
    ) {
      resultsUploadPdfModal.show({
        existingUserResultId: userResult?.id,
        existingFileUrl: userResult?.attributes.file,
        patientId,
        onClose: () => resultsUploadPdfModal.remove(),
      })
    } else {
      editManualPatientResultModal.show({
        patientId,
        existingUserResultId: userResult?.id,
        uploadedFileUrl: userResult?.attributes.file || undefined,
        uploadedFileName: userResult?.attributes.file_name || undefined,
        onClose: () => editManualPatientResultModal.remove(),
      })
    }
  })

  const onClick = useEventCallback(() => {
    if (canEdit) {
      onEdit()
    } else if (canDownload) {
      onDownload()
    }
  })

  const onDownload = () => {
    if (canDownload) {
      window.open(userResult?.attributes.file!)
    }
  }

  const Icon = useCallback(
    (className: string) => {
      if (userResult?.attributes.latest_thumbnail) {
        return (
          <img
            className={cn(className, "w-[90px] h-[111px]")}
            src={userResult?.attributes.latest_thumbnail}
            alt="Report Thumbnail"
          />
        )
      }
      return (
        <ManualPatientResultDocumentIcon
          className={"min-w-[90px] shadow-xl rounded-sm"}
        />
      )
    },
    [document]
  )

  const biomarkerCount =
    userResult?.attributes.total_biomarker_results_count ?? 0

  let subTitle =
    `${document.attributes.origin_description}` +
    (biomarkerCount > 0
      ? ` • ${biomarkerCount} biomarker${biomarkerCount > 1 ? "s" : ""}`
      : "")

  return (
    <PatientDocumentCard
      onClick={onClick}
      Icon={Icon}
      preTitle={userResult?.attributes.lab_company_results_name?.toUpperCase()}
      title="Lab Results Data"
      subTitle={subTitle}
      actions={
        <DocumentCardActionsMenu
          onDownload={canDownload ? onDownload : undefined}
          onDelete={canDelete ? () => onDelete(document) : undefined}
          onEdit={canEdit ? onEdit : undefined}
          editIcon={<ResultsEditIcon />}
          editLabel={
            isPendingTrackingInTrends
              ? "Prepare to Track in Trends"
              : "Edit Data"
          }
          deleteLabel="Remove Data"
        />
      }
    />
  )
}

export default ImportedUserResultDocumentCard
